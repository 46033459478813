<template>
  <div v-cloak>
    <LoadingComponent v-if="!apiLoaded" :text="loadingText"></LoadingComponent>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1 style="padding-right: 60px">
            Паспорт офиса ЦЗН
            <span>Для сети ЦЗН централизованной или смешанной модели управления под ЦЗН (офисом ЦЗН)
              понимается точка приема либо управляющий ЦЗН</span>
            <!--            <span-->
            <!--                v-if="$user.role==='ROLE_CZN_MANAGER' || $user.role==='ROLE_CZN_TERRITORIAL'">{{ userRegion.name }}</span>-->
            <!--            &lt;!&ndash;            <span v-if="$user.role==='ROLE_CZN'">{{userRegion.name}}</span>&ndash;&gt;-->
            <!--            <v-autocomplete-->
            <!--                v-else-->
            <!--                style="width: 500px; margin-top: 10px"-->
            <!--                dense-->
            <!--                label="Регион"-->
            <!--                :items="regionList"-->
            <!--                item-text="name"-->
            <!--                return-object-->
            <!--                v-model="userRegion"-->
            <!--                @input="initialize()"-->
            <!--            ></v-autocomplete>-->
            <span v-if="fromRegionPassport" class="subheader">{{ userCzn.name }}</span>
            <department-info v-else
                @reginput="userRegion = $event"
                @depObj="userCzn = $event; initializeData()"
            ></department-info>

            <div class="download-menu">
              <v-dialog
                  v-model="passportCZNExportDialog"
                  max-width="600px"
                  persistent>
                <template v-slot:activator="{ on }">
                  <div class="download-menu-icon" v-on="on">
                    <svg
                        fill="none"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          d="M8 17V15H16V17H8ZM16 10L12 14L8 10H10.5V7H13.5V10H16ZM5 3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3ZM5 5V19H19V5H5Z"
                          fill="#0033A0"
                      />
                    </svg>
                  </div>
                </template>

                <PassportCZNExportDialog
                    @close="passportCZNExportDialog=false; exportDialogKey++"
                    :is-from-config="false"
                    :region="userRegion"
                    :czn="userCzn.name"
                    :key="exportDialogKey"/>
              </v-dialog>
            </div>

            <div v-if="$user.role !== 'ROLE_CZN_TERRITORIAL' && !fromRegionPassport">
              <v-checkbox
                  v-model="disableEditingToTerritorialCZN"
                  @click="changeEditStatus()"
                  label="Запретить редактирование территориальным ЦЗН"
                  style="float: left; margin-top: 0"
              ></v-checkbox>
              <v-btn v-if="config.active"
                     @click="$router.push('/passportczn/massEdit')"
                     outlined
                     style="float: right">
                Указать данные по всем ЦЗН региона
              </v-btn>
            </div>
          </h1>

          <div v-if="editable && photos.length < 20" class="mb-5">
            <v-btn outlined @click="$refs['photoInput'].click()">добавить фото</v-btn>
            <input ref="photoInput" style="display: none" type="file" @change="addPhoto()">
          </div>

          <div>
            <VueSlickCarousel v-bind="settings" v-if="photos.length > 0" :key="componentKey">
              <div v-for="(photo, photoIndex) in photos"
                   :key="photoIndex + 'photoIndex'"
                   style="position: relative">
                <img :src="resultPhotos[photoIndex]" alt="" class="photo"/>
                <span> <!-- style="display: flex; flex-direction: row!important; justify-content: center" -->
                  <v-btn class="round-btn delbtn" @click="downloadPhoto(photoIndex)" title="Скачать фото">
                    <v-icon>mdi-tray-arrow-down</v-icon>
                  </v-btn>
                  <v-btn v-if="editable" class="round-btn delbtn" @click="confirmToDelete(photo.id)" title="Удалить фото">
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </span>
              </div>
            </VueSlickCarousel>
          </div>

          <div class="list passport">
            <div
                v-for="section in config.sections"
                :key="'liw' + section.id"
                class="list-item-wrap"
            >
              <v-expansion-panels>
                <v-expansion-panel :key="'vep' + section.id">
                  <v-expansion-panel-header>
                    <div class="list-item">
                      <a class="list-item-name">
                        <button class="toggleSwitch">
                          <svg
                              class="closed"
                              fill="none"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                                d="M12 14.5L17 9.5L7 9.5L12 14.5Z"
                                fill="#1551D0"
                            />
                          </svg>
                          <svg
                              class="opened"
                              fill="none"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                                d="M14.5 12L9.5 7L9.5 17L14.5 12Z"
                                fill="#1551D0"
                            />
                          </svg>
                        </button>
                        <span>{{ section.id }}. {{ section.name }}</span>
                      </a>
                      <v-progress-circular
                          :rotate="-90"
                          :size="50"
                          :value="getProgress(section.id)"
                          :width="6"
                          color="#0033A0"
                      >
                        {{ getProgress(section.id) }}
                      </v-progress-circular>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <table>
                      <thead>
                      <tr>
                        <th colspan="2">Показатели (данные)</th>
                        <th style="width: 300px;">
                          {{ showYear ? showYear : "Данные на текущий момент" }}
                        </th>
                        <th>
                          <svg
                              @click="previousYear()"
                              style="cursor: pointer"
                              fill="none"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                                d="M15.41 16.58L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.58Z"
                                fill="#0033A0"
                            />
                          </svg>
                          <svg
                              @click="nextYear()"
                              style="cursor: pointer"
                              fill="none"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                                d="M8.58997 16.58L13.17 12L8.58997 7.41L9.99997 6L16 12L9.99997 18L8.58997 16.58Z"
                                fill="#0033A0"
                            />
                          </svg>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="indicator in section.indicators">
                        <tr :key="userCzn.id + showYear + section.id + '.' + indicator.id">
                          <td>{{ section.id }}.{{ indicator.id }}</td>
                          <td>{{ indicator.name }}</td>
                          <td>
                            {{
                              indicator.dataType === 'OPTIONAL' ? (
                                      getValue(section.id, indicator.id, null) ?
                                          indicator.options.find(it => it.id == getValue(section.id, indicator.id, null))?.value :
                                          "&mdash;"
                                  ) :
                                  ((t = getValue(section.id, indicator.id, null)) ? t : "&mdash;")
                            }}
<!--                            {{ (t = getValue(section.id, indicator.id, null)) ? t : "&mdash;" }}-->
                            <template v-if="getRelevanceDate(section.id, indicator.id, null)">
                              <br>
                              <div class="additional-info">
                                <div>
                                  {{ getRelevanceDate(section.id, indicator.id, null) | dateFormat }}
                                </div>
                                <v-icon v-if="checkAdditionalInfo(section.id, indicator.id, null, 'COMMENT')">
                                  mdi-comment-outline
                                </v-icon>
                                <v-icon v-if="checkAdditionalInfo(section.id, indicator.id, null, 'FILE')">
                                  mdi-paperclip
                                </v-icon>
  <!--                              <template v-if="getIndicatorEditInfo(section.id, indicator.id, null)">-->
  <!--                                <a href="#" @click.prevent="downloadAttachment(getIndicatorEditInfo(section.id, indicator.id, null))">-->
  <!--                                  <v-icon>mdi-paperclip</v-icon>-->
  <!--                                  {{ truncateStr(getIndicatorEditInfo(section.id, indicator.id, null).storedFile.name, 15) }}-->
  <!--                                </a>-->
  <!--                              </template>-->
                              </div>
                            </template>
                          </td>
                          <td>
                            <PassportCZNIndicatorEditDialog
                                :config="getSubconfig(section.id, indicator.id, null)"
                                :config-id="config.id"
                                :section-id="section.id"
                                :indicator-id="indicator.id"
                                :sub-indicator-id="null"
                                :values="filterValues(section.id, indicator.id, null)"
                                :edit-info="getIndicatorEditInfo(section.id, indicator.id, null)"
                                :region="userRegion.code"
                                :editable="editable"
                                :disableEditingToTerritorialCZN="disableEditingToTerritorialCZN"
                                :czn="userCzn"
                                :relevance-date="getRelevanceDate(section.id, indicator.id, null)"
                                :key="editDialogKey"
                                @updated="initialize()"
                            />
                          </td>
                        </tr>
                        <tr v-for="subIndicator in indicator.subIndicators"
                            :key="userCzn.id + showYear + section.id + '.' + indicator.id + '.' + subIndicator.id">
                          <td>
                            {{ section.id }}.{{ indicator.id }}.{{ subIndicator.id }}
                          </td>
                          <td>{{ subIndicator.name }}</td>
                          <td>
                            {{
                              subIndicator.dataType === 'OPTIONAL' ? (
                                      getValue(section.id, indicator.id, subIndicator.id) ?
                                          subIndicator.options.find(it => it.id == getValue(section.id, indicator.id, subIndicator.id)).value :
                                          "&mdash;"
                                  ) :
                                  ((t = getValue(section.id, indicator.id, subIndicator.id)) ? t : "&mdash;")
                            }}
<!--                            {{ (t = getValue(section.id, indicator.id, subIndicator.id)) ? t : "&mdash;" }}-->
                            <br v-if="getRelevanceDate(section.id, indicator.id, subIndicator.id)">
                            <div class="additional-info">
                              <div>
                                {{ getRelevanceDate(section.id, indicator.id, subIndicator.id) | dateFormat }}
                              </div>
                              <v-icon v-if="checkAdditionalInfo(section.id, indicator.id, subIndicator.id, 'COMMENT')">
                                mdi-comment-outline
                              </v-icon>
                              <v-icon v-if="checkAdditionalInfo(section.id, indicator.id, subIndicator.id, 'FILE')">
                                mdi-paperclip
                              </v-icon>
<!--                              <template v-if="getIndicatorEditInfo(section.id, indicator.id, subIndicator.id)">-->
<!--                                <a href="#" @click.prevent="downloadAttachment(getIndicatorEditInfo(section.id, indicator.id, subIndicator.id))">-->
<!--                                  <v-icon>mdi-paperclip</v-icon>-->
<!--                                  {{ truncateStr(getIndicatorEditInfo(section.id, indicator.id, subIndicator.id).storedFile.name, 15) }}-->
<!--                                </a>-->
<!--                              </template>-->
                            </div>
                          </td>
                          <td>
                            <PassportCZNIndicatorEditDialog
                                :config="getSubconfig(section.id, indicator.id, subIndicator.id)"
                                :config-id="config.id"
                                :section-id="section.id"
                                :indicator-id="indicator.id"
                                :sub-indicator-id="subIndicator.id"
                                :values="filterValues(section.id, indicator.id, subIndicator.id)"
                                :edit-info="getIndicatorEditInfo(section.id, indicator.id, subIndicator.id)"
                                :region="userRegion.code"
                                :editable="editable"
                                :disableEditingToTerritorialCZN="disableEditingToTerritorialCZN"
                                :czn="userCzn"
                                :relevance-date="getRelevanceDate(section.id, indicator.id, subIndicator.id)"
                                :key="editDialogKey"
                                @updated="initialize()"
                            />
                          </td>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </div>

        <div
            v-if="errorText != null"
            class="error"
            style="position: fixed; bottom: 0"
        >
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height: 1000px"></div>
    </transition>
  </div>
</template>

<script>
import api from "@/modules/api";
import PassportCZNIndicatorEditDialog from "@/components/dialogs/PassportCZNIndicatorEditDialog";
import departmentInfo from "@/components/elements/DepartmentInfo";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css"
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css"
import PassportCZNExportDialog from "@/components/dialogs/PassportCZNExportDialog";
import LoadingComponent from "@/components/elements/LoadingComponent";

export default {
  name: "PassportCZNInfo",
  components: {
    LoadingComponent,
    PassportCZNExportDialog,
    PassportCZNIndicatorEditDialog,
    VueSlickCarousel,
    departmentInfo
  },

  data() {
    return {
      config: null,
      values: null,
      indicatorsEditInfo: null,
      apiLoaded: false,
      loadingText: [],
      editable: false,
      disableEditingToTerritorialCZN: false,
      userRegion: {},
      regionList: [],
      errorText: null,
      showYear: null,
      dialog: false,
      userCzn: {},
      photos: [],
      resultPhotos: [],
      originalPhotoNames: [],
      componentKey: 0,
      editDialogKey: 0,
      exportDialogKey: 0,
      availableImageType: [
          'image/gif',
          'image/jpeg',
          'image/png',
          'image/svg+xml',
          'image/tiff',
          'image/webp',
          'image/avif',
          'image/bmp'
      ],
      settings: {
        arrows: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      passportCZNExportDialog: false,
      fromRegionPassport: null,
    };
  },

  methods: {
    forceRerender() {
      this.componentKey += 1;
      this.editDialogKey += 1;
    },

    nextYear() {
      if (this.showYear == null) this.showYear = 2021;
      else if (this.showYear === 2022) this.showYear = null;
      else this.showYear++;
    },

    previousYear() {
      if (this.showYear == null) this.showYear = 2022;
      else if (this.showYear === 2021) this.showYear = null;
      else this.showYear--;
    },

    getValue(sectionId, indicatorId, subIndicatorId) {
      for (let v of this.values) {
        if (
            v.sectionId === sectionId &&
            v.indicatorId === indicatorId &&
            v.subIndicatorId === subIndicatorId
        ) {
          if (v.year === this.showYear) return v.value;
        }
      }
    },

    getRelevanceDate(sectionId, indicatorId, subIndicatorId) {
      for (let v of this.values) {
        if (
            v.sectionId === sectionId &&
            v.indicatorId === indicatorId &&
            v.subIndicatorId === subIndicatorId
        ) {
          if (v.year === this.showYear) return v.relevanceDate;
        }
      }
    },

    checkAdditionalInfo(sectionId, indicatorId, subIndicatorId, type) {
      let info = this.getIndicatorEditInfo(sectionId, indicatorId, subIndicatorId);
      if (info != null) {
        if (type === "FILE") {
          return info.storedFile
        } else if (type === "COMMENT") {
          return info.comment
        }
      } else {
        return false
      }
    },

    getIndicatorEditInfo(sectionId, indicatorId, subIndicatorId) {
      if (this.indicatorsEditInfo == null) return null;
      for (let v of this.indicatorsEditInfo) {
        if (
            v.sectionId === sectionId &&
            v.indicatorId === indicatorId &&
            v.subIndicatorId === subIndicatorId
        ) {
          return v;
        }
      }
    },

    truncateStr(str, n) {
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },

    async downloadAttachment(file) {
      await this.$downloadApi(file.storedFile.name,"downloadFile", {fileId: file.storedFile.id})
    },

    async loadConfig() {
      let req;
      if (this.configId == null) {
        req = await this.$getApi("/passportCzn/getActiveConfig");
      } else {
        req = await this.$getApi("/passportCzn/getConfig", {id: this.configId});
      }
      if (req.ok) {
        this.config = req.payload;
        this.config.sections = JSON.parse(this.config.sectionsJson);
        // console.log('this.config', this.config)
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    filterValues(sectionId, indicatorId, subIndicatorId) {
      let resv = [];
      for (let v of this.values) {
        if (
            v.sectionId === sectionId &&
            v.indicatorId === indicatorId &&
            v.subIndicatorId === subIndicatorId
        )
          resv.push({
            region: v.region,
            configId: v.configId,
            sectionId: v.sectionId,
            indicatorId: v.indicatorId,
            subIndicatorId: v.subIndicatorId,
            czn: v.czn,
            relevanceDate: v.relevanceDate,
            year: v.year,
            value: v.value,
          });
      }
      return resv;
    },

    getSubconfig(sectionId, indicatorId, subIndicatorId) {
      for (let section of this.config.sections) {
        if (section.id === sectionId) {
          for (let indicator of section.indicators) {
            if (indicator.id === indicatorId) {
              if (subIndicatorId == null) {
                return indicator;
              }
              for (let subIndicator of indicator.subIndicators) {
                if (subIndicator.id === subIndicatorId) return subIndicator;
              }
            }
          }
        }
      }
    },

    getProgress(sectionId) {
      return Math.round(
          (this.getSectionValuesPresent(sectionId) /
              this.getSectionValuesCount(sectionId)) *
          100
      );
    },

    getSectionValuesCount(sectionId) {
      let count = 0;
      for (let section of this.config.sections) {
        if (section.id === sectionId) {
          count += section.indicators.length;
          for (let indicator of section.indicators) {
            if (indicator.subIndicators != null)
              count += indicator.subIndicators.length;
          }
        }
      }
      return count;
    },

    getSectionValuesPresent(sectionId) {
      let count = 0;
      for (let v of this.values) {
        if (v.sectionId === sectionId && v.year == null) count++;
      }
      return count;
    },

    async loadValues() {
      let req = await this.$getApi("/passportCzn/getValues", {
        region: this.userRegion.code,
        czn: this.userCzn.name,
        configId: this.config.id,
      });
      if (req.ok) {
        this.values = req.payload;
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    async loadIndicatorsEditInfo() {
      let req = await this.$getApi("/passportCzn/getIndicatorsEditInfo", {
        region: this.userRegion.code,
        czn: this.userCzn.name,
        configId: this.config.id,
      });
      if (req.ok) {
        this.indicatorsEditInfo = req.payload;
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    // getSection(sectionId) {
    //   for (let s of this.config.sections) {
    //     if (s.id === sectionId) return s;
    //   }
    // },
    //
    // getIndicator(section, indicatorId) {
    //   for (let i of section.indicators) {
    //     if (i.id === indicatorId) return i;
    //   }
    // },
    //
    // getSubIndicator(indicator, subIndicatorId) {
    //   for (let si of indicator.subIndicators) {
    //     if (si.id === subIndicatorId) {
    //       return si;
    //     }
    //   }
    // },

    async getEditStatus() {
      let req = await this.$getApi("/passportCzn/isEditable", {departmentId: this.userCzn.id});
      if (req.ok) {
        this.disableEditingToTerritorialCZN = !req.payload;
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    async changeEditStatus() {
      let req = await api.postJson(
          "/passportCzn/changeEditable",
          null,
          {departmentId: this.userCzn.id, status: !this.disableEditingToTerritorialCZN});
      if (req.ok) {
        this.disableEditingToTerritorialCZN = !req.payload.editable;
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    async addPhoto() {
      let file = this.$refs['photoInput'].files[0];
      if (file.size > 100000000) {
        alert('Файл больше 100Мб. Загрузите, пожалуйста, файл с меньшим размером');
        this.$refs['photoInput'][0].value = null;
        return
      }
      if (!this.availableImageType.includes(file.type)) {
        alert('Загрузите, пожалуйста, файл в одном из допустимых форматов изображения: \n' +
            ' jpg, png, webp, avif, gif, svg, tiff, bmp');
        this.$refs['photoInput'][0].value = null;
        return
      }

      let formData = new FormData();
      formData.append('file', file);

      let req = await api.postFile("/passportCzn/savePhoto",
          {
            region: this.userRegion.name,
            czn: this.userCzn.id
          },
          formData,
          "POST");
      if (req.ok) {
        console.log("Success")
      }

      this.photos = await this.loadPhotos(this.userRegion.name, this.userCzn.id);
      this.updatePhotos();

      this.$refs['photoInput'].value = null;
    },

    async loadPhotos(region, czn) {
      let req = await this.$getApi("/passportCzn/getPhotos", {region: region, czn: czn});
      if (req.ok) {
        return req.payload;
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    async updatePhotos() {
      this.forceRerender();
      this.resultPhotos = [];
      this.originalPhotoNames = [];
      for (let photo of this.photos) {
        let pic = await this.getPhoto(photo.id);
        this.resultPhotos.push(pic);
        this.originalPhotoNames.push(photo.originalName);
      }
    },

    async getPhoto(id) {
      let req = await api.get("/passportCzn/downloadPhoto", {photoId: id}, "blob");
      if (req.ok) {
        // return req.payload;
        return URL.createObjectURL(req.payload);
      } else {
        alert("Ошибка загрузки данных");
      }
    },

    confirmToDelete(id) {
      let result = confirm('Вы уверены, что хотите удалить фотографию?');
      if (result) {
        this.deletePhoto(id);
      }
    },

    downloadPhoto(photoIndex) {
      let link = document.createElement('a');
      link.href = this.resultPhotos[photoIndex];
      link.download = this.originalPhotoNames[photoIndex];
      link.click();
      URL.revokeObjectURL(link.href);
    },

    async deletePhoto(id) {
      await api.delete("/passportCzn/deletePhoto", {photoId: id});
      this.photos = await this.loadPhotos(this.userRegion.name, this.userCzn.id);
      this.updatePhotos();
      this.$refs['photoInput'].value = null;
    },

    async initialize() {
      await this.loadConfig();
      await this.loadValues();
      await this.loadIndicatorsEditInfo();
      await this.getEditStatus();
    },

    async initializeData() {
      await this.loadValues();
      await this.loadIndicatorsEditInfo();
      await this.getEditStatus();
      this.photos = await this.loadPhotos(this.userRegion.name, this.userCzn.id);
      this.updatePhotos();

      this.editable = (this.config.active) &&
          (this.$user.role === 'ROLE_CZN_MANAGER' ||
              this.$user.role === 'ROLE_CZN_TERRITORIAL' ||
              this.$user.role === 'ROLE_ADMIN');
    },

    getDepartmentById(id) {
      for (let region of this.regionList) {
        for (let dep of region.departments) {
          if (dep.id === Number(id)) {
            return dep
          }
        }
      }
    },
  },

  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  async beforeMount() {
    this.configId = this.$route.params.configId;

    this.regionList = this.$cznDepartmentsByRegionList;

    if (this.$user.role === 'ROLE_CZN_TERRITORIAL' || this.$user.role === 'ROLE_CZN_MANAGER') {
      this.userRegion = this.$user.region;
      this.userCzn = this.$user.department;
    } else if (this.$user.role === 'ROLE_REGIONAL_AGENCY') {
      this.userRegion = this.$user.region;
      // Находим регион пользователя в $cznDepartmentsByRegionList для того, чтобы из него получить список ЦЗН региона
      let region = this.$cznDepartmentsByRegionList.filter((region) => {return region.name === this.userRegion.name})
      this.userCzn = region[0].departments[0];
    } else { //для админов и ВНИИ Труда
      this.userRegion = this.regionList[0];
      this.userCzn = this.userRegion.departments[0];
    }

    let departmentId = this.$route.params.departmentId;
    if (departmentId != null) { //при прямом переходе из паспорта региона
      this.fromRegionPassport = true;
      this.userCzn = this.getDepartmentById(departmentId);
    }

    this.loadingText.push('Загружаются данные паспорта ЦЗН')
    await this.initialize();
    this.loadingText.splice(this.loadingText.length - 1, 1)
    this.loadingText.push('Загружены данные паспорта ЦЗН')

    this.editable = !this.fromRegionPassport
            && (this.$user.role === 'ROLE_CZN_MANAGER'
            || this.$user.role === 'ROLE_CZN_TERRITORIAL'
            || this.$user.role === 'ROLE_ADMIN')
            && this.config.active;

    this.apiLoaded = true;

    if (this.$user.role === 'ROLE_CZN_TERRITORIAL' || this.fromRegionPassport) {
      this.photos = await this.loadPhotos(this.userRegion.name, this.userCzn.id);
      await this.updatePhotos();
      this.editable = !this.disableEditingToTerritorialCZN && !this.fromRegionPassport
    }
  },
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style scoped lang="scss">
.download-menu {
top: 20px;
}

.photo {
  max-width: 400px;
  max-height: 200px;
}

.additional-info {
  div {
    display: inline-block;
    min-width: 65px;
    font-size: 13px;
    font-weight: 500;
    color: #777779;
  }
  .v-icon {
    display: inline-block;
    margin-left: 5px;
    font-size: 14px;
    color: #777779;
  }
  a {
    font-size: 12px;
  }
}

.delbtn {
  // position: absolute;
  // top: 10px;
  // right: 10px;
  margin-left: 100px;
  .v-icon {
    font-size: 16px;
    color: #0033A0;
  }
}

.subheader {
  font-size: 17px;
  color: #1C1C1F;
  font-weight: 600;
}
</style>
